import { Component } from '@angular/core';
// Device detector
import { DeviceDetectorService } from 'ngx-device-detector';
// Alerts
import Swal from 'sweetalert2';
// Modal service
import { ModalAppService } from './modal-app.service';
import { LanguageService } from '../../services/language/language.service';
import { SidebarService } from '../../services/shared/sidebar.service';
import { SettingsService } from '../../services/settings/settings.service';
import { UserService } from '../../services/user/user.service';

/**
 * Component for the user to select an apps
 */
@Component({
  selector: 'app-modal-app',
  templateUrl: './modal-app.component.html',
  styleUrls: ['./modal-app.component.css'],
})
export class ModalAppComponent {
  backgroundClass: string;

  deviceInfo = null;

  /**
   * Following variables declared
   * @param modalAppService Variable to show/hide modal
   * @param languageService Service that works with the page language
   * @param settings Service that works the page colour
   * @param sidebarService Service that works with the sidebar and the app of the page
   */
  constructor(
    public modalAppService: ModalAppService,
    public languageService: LanguageService,
    public userService: UserService,
    public sidebarService: SidebarService,
    public settings: SettingsService,
    private deviceService: DeviceDetectorService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (
      this.deviceInfo.browser === 'Firefox' ||
      this.deviceInfo.browser === 'IE'
    ) {
      this.backgroundClass = 'fondo-negro';
    } else {
      this.backgroundClass = 'fondo-difuminado';
    }
  }

  /**
   * Function that allows to close the modal using the service of the modal
   */
  closeModal() {
    this.modalAppService.hideModal();
    window.location.reload();
    return true;
  }

  /**
   * Function that allows you to apply the theme and app to the page based on what the user selected
   * @param app Variable that brings the app selected by the user
   */
  applyApp(app: string) {
    if (app === 'Lab4Physics') {
      this.sidebarService.applyApp('LAB4PHYSICS');
      this.settings.applyTheme('red');
      this.closeModal();
      return 'LAB4PHYSICS';
    }
    if (app === 'Lab4Chemistry') {
      this.sidebarService.applyApp('LAB4CHEMISTRY');
      this.settings.applyTheme('purple');
      this.closeModal();
      return 'LAB4PHYSICS';
    }
    if (app === 'Lab4Biology') {
      this.sidebarService.applyApp('LAB4BIOLOGY');
      this.settings.applyTheme('green');
      this.closeModal();
      return 'LAB4PHYSICS';
    }
    Swal.fire('Error', 'error', 'error');
    return '';
  }
}
