<!-- TOP NAVBAR -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand">
    <!-- LOGO -->
    <div class="">
      <a class="navbar-brand" routerLink="/sign-in">
        <div class="navbar-brand-wrap row">
          <!-- IMAGE -->
          <div
            class="resp-img-container d-block d-sm-none my-auto topbar-icon mr-3"
          >
            <img
              class="logo-image"
              src="./assets/images/gallery/portal-logo.png"
              alt="homepage"
            />
          </div>

          <!-- TEXT -->
          <div class="resp-img-container my-auto d-none d-sm-block">
            <img
              class="logo-text-image"
              src="./assets/images/gallery/portal-ext-logo.png"
              alt="homepage"
              height="100"
            />
          </div>
        </div>
      </a>
    </div>

    <!-- MENU ELEMENTS -->
    <div class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <!-- SING-UP CTA -->
        <li class="nav-item dropdown row">
          <a
            routerLink="/sign-up"
            class="register dropdown-item flat-button pointer p-0 my-auto"
          >
            {{ 'HEADER.SIGN-UP' | transloco }}
          </a>
        </li>

        <!-- SEPARATOR -->
        <li class="nav-item dropdown mx-2" style="font-size: 30px">
          <label class="separation"> | </label>
        </li>

        <!-- LANGUAGE SELECTORS -->
        <li class="nav-item language flat-button row">
          <div class="my-auto">
            <a (click)="changeLanguage('en')" class="change-language pointer"
              >EN
            </a>
            <span class="cursor separation">|</span>
            <a (click)="changeLanguage('es')" class="change-language pointer">
              ES</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<section id="wrapper">
  <img src="../../assets/images/images/forgot-password-illustration-1.svg" />
  <div class="login-register">
    <div class="login-box card">
      <div class="card-body">
        <form
          ngNativeValidate
          #f="ngForm"
          class="form-horizontal form-material"
          (ngSubmit)="recoverPassword(f)"
        >
          <h3 class="box-title cursor general-title m-b-20">
            {{ 'FORGOT-PASSWORD.FORGOT-PSW' | transloco }}
          </h3>
          <p class="paragraph cursor">
            {{ 'FORGOT-PASSWORD.PARAGRAPH' | transloco }}
          </p>
          <div class="form-group">
            <div class="col-xs-12">
              <input
                [ngModel]="userEmail"
                [ngClass]="{
                  incorrect: isIncorrect
                }"
                name="userEmail"
                userEmail="email"
                class="form-control"
                type="email"
                required
                placeholder="{{ 'FORGOT-PASSWORD.PLACEHOLDER' | transloco }}"
              />
            </div>
            <span class="incorrect-email" *ngIf="isIncorrect">{{
              'FORGOT-PASSWORD.INCORRECT-EMAIL' | transloco
            }}</span>
          </div>
          <div class="form-group button-container text-center p-b-10">
            <div class="col-xs-12">
              <button
                [disabled]="isLoading"
                class="submit-button btn btn-lg btn-block call-to-action btn-rounded btn-cards"
                type="submit"
              >
                {{ 'FORGOT-PASSWORD.SEND-EMAIL' | transloco }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <img src="../../assets/images/images/forgot-password-illustration-2.svg" />
</section>
