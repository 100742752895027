import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { mixpanelEvent } from 'src/app/config/mixpanel-events';
import { MixpanelService } from 'src/app/services/analytics/mixpanel.service';
import { LanguageService } from 'src/app/services/language/language.service';
import Swal from 'sweetalert2';

const VIEW_NAME = 'Home';

@Component({
  selector: 'app-section-button',
  templateUrl: './section-button.component.html',
  styleUrls: ['./section-button.component.css'],
})
export class SectionButtonComponent implements OnInit {
  language: string;
  /**
   * @param userType to get the user Type of the user and validate accordingly.
   */
  @Input() userType: string = '';
  /**
   * @param linkToSection contains the link that the component will redirect to
   */
  @Input() linkToSection: string = '';
  /**
   * @param titleSection contains the title that the component will display to the user
   */
  @Input() titleSection: string = '';
  /**
   * @param subtitleSection contains the subtitle that the component will display to the user, if given.
   */
  @Input() subtitleSection: string = '';
  /**
   * @param imageSection contains the image that the component will display to the user
   */
  @Input() imageSection: string = '';
  /**
   * @param isActive used to validate if the component is active or 'coming Soon'.
   */
  @Input() isActive: boolean = false;
  /**
   * @param inactiveText used to display a title if the component is not active or 'coming Soon'.
   */
  @Input() inactiveText: string = '';

  /**
   * @param backgroundClass used to change the color of the background
   */
  @Input() backgroundClass: string = '';
  /**
   * @param isTeacher to validate the user type
   */
  isTeacher: boolean = false;
  /**
   * @param dealTag to include a tag with an icon of the deal if given one.
   */
  @Input() dealTag: string = '';

  @Input() clickEvent?: string;
  @Input() showview: string = '';

  swalWithGreenAndRedButton = Swal.mixin({
    customClass: {
      confirmButton:
        'css-button-fully-rounded css-button-fully-rounded--green mx-1 mb-1',
      cancelButton:
        'css-button-fully-rounded btn btn-primary primary-buton-blue-bg mx-1 mb-1',
      popup: 'custom-swal-modal',
    },
    buttonsStyling: false,
  });

  constructor(
    public mixpanelService: MixpanelService,
    public router: Router,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.isTeacher = this.userType === 'TEACHER';
    this.language = this.languageService.getLanguage();
  }

  /**
   * Handles the click event of the component.
   */
  clickOnComponentEvent() {
    switch (this.clickEvent) {
      case 'classroomsSelected':
        this.classroomsSelected();
        break;
      case 'themesSelected':
        this.themesSelected();
        break;
      case 'trainingQuizzesSelected':
        this.trainingQuizzesSelected();
        break;
      case 'planningSelected':
        this.planningSelected();
        break;
      default:
        break;
    }
  }

  planningSelected() {
    if (this.showview === 'planningView' && this.language === 'es') {
      this.triggerGoToMainViewFeatureEvent();
      this.router.navigate([this.linkToSection]);
    } else {
      this.swalWithGreenAndRedButton
        .fire({
          title: 'Only available in Spanish',
          text: 'This function is only available in Spanish. If you wish to continue, it will all be in Spanish',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Back',
          cancelButtonText: 'Continue to Spanish version',
        })
        .then((result) => {
          //* In this case, the cancel button redirects to the Spanish version, reason why we look for a false value.
          if (!result.value) {
            this.triggerGoToMainViewFeatureEvent();
            this.router.navigate([this.linkToSection]);
          }
        });
    }
  }

  classroomsSelected() {
    this.mixpanelService.track(mixpanelEvent.HOME_CLASSROOMS_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
    this.router.navigate([this.linkToSection]);
  }

  themesSelected() {
    this.mixpanelService.track(mixpanelEvent.HOME_THEMES_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
    this.router.navigate([this.linkToSection]);
  }

  trainingQuizzesSelected() {
    this.mixpanelService.track(mixpanelEvent.HOME_TRAINING_QUIZZES_SELECTED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
    this.router.navigate([this.linkToSection]);
  }

  triggerGoToMainViewFeatureEvent() {
    this.mixpanelService.track(
      mixpanelEvent.GENERATOR_GO_TO_MAIN_VIEW_FEATURE,
      {
        source_name: mixpanelEvent.SOURCE_NAME,
        feature_name: mixpanelEvent.GENERATOR_FEATURE_NAME,
        view_name: VIEW_NAME,
        event_version: 1,
        event_id: 211,
        event_type: 'Click',
      },
    );
  }
}
