import { Injectable } from '@angular/core';
import { API_V3_URL } from '../../config/config';
import { ApiCallBase } from './apiCallBase';
import { Observable } from 'rxjs/internal/Observable';
import { LanguageService } from '../language/language.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  TeacherInfoResDTO,
  TeacherAssignClassroomDTO,
} from '../../models/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { classroomInfoDTO } from 'src/app/models/classrooms.model';
import { GlobalStateService } from 'src/app/global-state.service';
@Injectable({
  providedIn: 'root',
})
export class ClientSystemService extends ApiCallBase {
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    //added to make 'extend apicallbase' work properly
    super(languageService, http, router, globalStateService);
  }

  getUserId() {
    return this.userId;
  }

  getTeacherInfo(): Observable<TeacherInfoResDTO> {
    const url = `${API_V3_URL}/deal-manager/user-clisys-assignation?user_id=${this.userId}`;
    return this.http.get<TeacherInfoResDTO>(url, this.getHttpOptionsV3()).pipe(
      map((response: any) => this.convertKeysToCamelCase(response.response)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          if (this.language === 'es')
            return throwError(
              `Error ${error.status} Hubo un error desconocido`,
            );
          return throwError(`Error ${error.status} Unknown Error`);
        }
        if (error.status === 404) {
          if (this.language === 'es')
            return throwError(
              `Error ${error.status}, El usuario no está asignado a ninguna Institución con un deal activo `,
            );
          return throwError(
            `Error ${error.status}, The user isn't assigned to any Institution with an active Deal`,
          );
        }
        if (error.status === 422) {
          if (this.language === 'es')
            return throwError(
              `Error ${error.status}, Failed because of missing fields`,
            );
          return throwError(
            `Error ${error.status},  Fallo por falta de campos`,
          );
        } else {
          if (this.language === 'es')
            return throwError(
              `Error ${error.status}, Hubo un error desconocido`,
            );
          return throwError(`Error ${error.status}, Unknown error`);
        }
      }),
    );
  }

  //* Gets classroom profile
  getClassroomInfo(
    class_id: string,
    deal_id: string,
  ): Observable<classroomInfoDTO> {
    const url = `${API_V3_URL}/deal-manager/classroom/${class_id}?deal_id=${deal_id}&user_id=${this.userId}`;

    return this.http.get<classroomInfoDTO>(url, this.getHttpOptionsV3()).pipe(
      map((response: any) => {
        return this.convertKeysToCamelCase(response.response);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          if (this.language === 'es')
            return throwError(
              `Error ${error.status} Hubo un error desconocido`,
            );
          return throwError(`Error ${error.status} There was an unknown error`);
        }
        if (error.status === 404) {
          if (this.language === 'es')
            return throwError(
              'Clase no encontrada. Contáctenos para más información: support@lab4u.co',
            );
          return throwError(
            'Classroom not found. Get in touch with us for more information: support@lab4u.co',
          );
        } else {
          if (this.language === 'es')
            return throwError(
              `Hubo un error desconocido. Contáctenos para más información: support@lab4u.co`,
            );
          return throwError(
            `Unknown error. Get in touch with us for more information: support@lab4u.co`,
          );
        }
      }),
    );
  }

  //*Assign user to classroom-deal
  updateTeacherInfo(
    dto: TeacherAssignClassroomDTO,
  ): Observable<TeacherAssignClassroomDTO> {
    const url = `${API_V3_URL}/deal-manager/assign-user-to-clisys`;

    return this.http
      .post<TeacherAssignClassroomDTO>(`${url}`, dto, this.getHttpOptionsV3())
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            if (this.language === 'es')
              return throwError(
                `Error ${error.status}, hubo un error desconocido`,
              );
            return throwError(
              `Error ${error.status}, there was an unknown error`,
            );
          }
          if (error.status === 480) {
            if (this.language === 'es')
              return throwError(`Error ${error.status}, Deal no encontrado`);
            return throwError(`Error ${error.status}, Deal not found`);
          }
          if (error.status === 481) {
            if (this.language === 'es')
              return throwError(`Error ${error.status}, Usuario no encontrado`);
            return throwError(`Error ${error.status}, User not found`);
          }
          if (error.status === 482) {
            if (this.language === 'es')
              return throwError(
                `Error ${error.status}, Una o más clases no fueron encontradas`,
              );
            return throwError(
              `Error ${error.status}, One or more Classroom were not found`,
            );
          }
          if (error.status === 422) {
            if (this.language === 'es')
              return throwError(
                `Error ${error.status}, Una o más clases no fueron encontradas`,
              );
            return throwError(
              `Error ${error.status}, One or more Classroom were not found`,
            );
          } else {
            if (this.language === 'es')
              return throwError(
                `Error ${error.status}, Hubo un error desconocido`,
              );
            return throwError(`Error ${error.status}, Unknown error`);
          }
        }),
      );
  }

  //***** EDIT USER DATA */
  updateUserData(user_id: number, firstName: string, lastName: string) {
    const url = `${API_V3_URL}/auth/update-user`;
    const body = {
      user_id,
      first_name: firstName,
      last_name: lastName,
    };
    return this.http.patch(url, body, this.getHttpOptionsV3()).pipe(
      catchError((error) => {
        let errorMessage;
        if (error.status === 400)
          errorMessage =
            this.language === 'es' ? 'Error desconocido' : 'Unknown error';
        else if (error.status === 481)
          errorMessage =
            this.language === 'es' ? 'Usuario no encontrado' : 'User not found';
        else if (error.status === 422)
          errorMessage =
            this.language === 'es'
              ? 'Faltan datos requeridos'
              : 'Missing required data';
        else errorMessage = `Error ${error.status}, ${error.error.message}`;
        return throwError(errorMessage);
      }),
    );
  }

  //***** DEACTIVATE STUDENT CODE */
  deactivateStudentCode(user_id: string, code: string) {
    const url = `${API_V3_URL}/activation-code/undo-code-use`;
    //* user_id is the ID of the user making the request, while user_id_query is the ID of the user whose code is being deactivated.
    const body = { user_id: this.userId, code, user_id_query: user_id };
    return this.http.post(url, body, this.getHttpOptionsV3()).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        switch (error.status) {
          case 480:
            if (error.error.message.includes('user_id_query'))
              errorMessage =
                this.language === 'es'
                  ? 'El usuario que desea desactivar no existe. Contáctenos para más información: support@lab4u.co'
                  : 'The user you are trying to deactivate does not exist. Get in touch with us for more information: support@lab4u.co';
            else
              errorMessage =
                this.language === 'es'
                  ? 'Su cuenta no se ha encontrado en nuestro sistema. Contáctenos para más información: support@lab4u.co'
                  : 'Your account was not found in our system. Get in touch with us for more information: support@lab4u.co';
          case 490:
            errorMessage =
              this.language === 'es'
                ? 'El usuario no ha usado el código de esta clase'
                : 'The user has not used the code of this classroom';
            break;
          default:
            errorMessage =
              this.language === 'es'
                ? 'Ha ocurrido un error inesperado. Contáctenos para más información: support@lab4u.co'
                : 'An unexpected error has occurred. Get in touch with us for more information: support@lab4u.co';
        }
        return throwError(errorMessage);
      }),
    );
  }
}
