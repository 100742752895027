import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Alerts
import Swal from 'sweetalert2';

// Model
import { User } from '../models/user.model';

// Service
import { UserService } from '../services/user/user.service';
import { ModalComingSoonService } from '../components/modal-coming-soon/modal-coming-soon.service';
import { MixpanelService } from '../services/analytics/mixpanel.service';

// Modal
import { ModalUserTypeService } from '../components/modal-user-type/modal-user-type.service';

// Translator
import { LanguageService } from '../services/language/language.service';
import { TranslocoService } from '@ngneat/transloco';

// Crypt
import * as sha1 from 'js-sha1';

// Social login
// import {
//   AuthService,
//   GoogleLoginProvider,
//   FacebookLoginProvider,
// } from 'angular4-social-login';
import {
  SocialAuthService,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';

// RxJS
import { Subscription } from 'rxjs';

// SEO
import { SeoService } from '../services/seo/seo.service';
import { Country } from '../models/countries.model';
import { SelectCountriesService } from '../services/countries/select-countries.service';
import { PrivateRoutes } from '../config/config.routes';
import { GlobalStateService } from '../global-state.service';

/**
 * Declare plugins so then you can run it
 */
declare function init_plugins();

/**
 * Component to manage the register page
 */
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  countries: Country[];

  /** The form variable is declared
   * @param form Variable to save the user data
   */
  form: UntypedFormGroup;
  /** The language variable is declared
   * @param language Variable para guardar el idioma
   */
  language: string;
  userType: string;

  // userId = this.userService.getId();

  selectedCountry = '';

  tokenSHA: string = '';

  private subscription: Subscription = new Subscription();

  /** Following variables declared
   * @param socioAuthServ Variable from social networks
   * @param languageService Service that works with the page language
   * @param userService Service that brings users data and functions
   * @param router Variable that manage the routes
   * @param modalUserTypeService Variable to show/hide modal
   * @param translocoService Service that applies the selected language by the user
   */
  constructor(
    private socioAuthServ: SocialAuthService,
    public languageService: LanguageService,
    public userService: UserService,
    public router: Router,
    public modalUserTypeService: ModalUserTypeService,
    private translocoService: TranslocoService,
    public modalComingSoonService: ModalComingSoonService,
    public mixpanelService: MixpanelService,
    private seoService: SeoService,
    private title: Title,
    private selectService: SelectCountriesService,
    public globalStateService: GlobalStateService,
  ) {
    this.language = this.languageService.getLanguage();
  }
  /** The plugins start running to load the preloader, the Validators for the form are declared */
  ngOnInit() {
    init_plugins();
    this.tokenSHA = this.globalStateService.globalData?.TokenSHA1;
    this.countries = this.selectService.getCountries();

    this.language = this.languageService.getLanguage();

    // SEO
    let desc: string;
    let t: string;
    if (this.language === 'es') {
      t = 'Registrarme';
      desc =
        'Herramientas para priorizar lo que realmente importa. Este portal está hecho por profesores para profesores.';
    } else {
      t = 'Sign up';
      desc =
        'Tools to help you prioritize what matters most. This portal is made by teachers for teachers to support you!';
    }
    this.title.setTitle(t);
    this.seoService.generateTags({
      title: t,
      description: desc,
      slug: 'sign-up',
    });

    this.userType = JSON.parse(localStorage.getItem('Type'));

    this.form = new UntypedFormGroup(
      {
        first_name: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(3),
        ]),
        last_name: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(3),
        ]),
        email: new UntypedFormControl(null, [
          Validators.required,
          Validators.email,
        ]),
        country: new UntypedFormControl(null, [Validators.required]),
        pass: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
        conditions: new UntypedFormControl(false),
      },
      {
        validators: this.areTheyEquals('pass', 'confirmPassword'),
      },
    );

    localStorage.removeItem('Type');
  }

  // event handler for the select element's change event
  selectChangeHandler(event: any) {
    // update the ui
    this.selectedCountry = event.target.value;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /** Function that validates and register a new user */
  registerUser() {
    if (this.form.invalid) {
      if (this.language === 'es') {
        Swal.fire('Error', 'Datos inválidos', 'error');
      } else {
        Swal.fire('Error', 'Invalid data', 'error');
      }
      return;
    }

    const user = new User(
      this.form.value.first_name,
      this.form.value.last_name,
      this.form.value.email.toLowerCase(),
      (this.form.value.language = this.language),
      (this.form.value.login_type = 'lab4u'),
      (this.form.value.type = this.userType),
      (this.form.value.gender = 'other'),
      this.form.value.pass,
      (this.form.value.country = this.selectedCountry),
      (this.form.value.biz_type = 'B2C'),
      (this.form.value.app = 'lab4chemistry'),
      (this.form.value.isGuest = false),
      this.form.value.institution,
      this.form.value.actual_deal,
      this.form.value.institution_id,
      this.form.value.usergroup,
      this.form.value.token,
      this.form.value.validated_acount,
      this.form.value.image,
      this.form.value.userId,
    );

    // Set user type
    user.user_type = this.userType;

    //encrypt
    user.password = sha1(user.email + user.password + this.tokenSHA);

    //*add user
    this.subscription.add(
      this.userService.newUser(user).subscribe(() => {
        this.subscription.add(
          this.userService.login(user, false, false).subscribe({
            next: (_resp) => {
              this.router.navigate([PrivateRoutes.HOME]);
            },
          }),
        );
      }),
    );
  }

  socialSignUp(platform: string) {
    if (this.language === 'es') {
      Swal.fire({
        title: 'Cargando',
        text: 'Espere por favor',
        icon: 'info',
        showConfirmButton: false,
        timer: 5000,
        customClass: {
          popup: 'custom-swal-modal',
        },
      });
    } else {
      Swal.fire({
        title: 'Loading',
        text: 'Please wait',
        icon: 'info',
        showConfirmButton: false,
        timer: 5000,
        customClass: {
          popup: 'custom-swal-modal',
        },
      });
    }
    if (platform === 'Google') {
      platform = GoogleLoginProvider.PROVIDER_ID;
      this.socioAuthServ
        .signIn(platform)
        .then(
          (response) => {
            const user = new User();
            user.first_name = response.name;
            user.last_name = '';
            user.email = response.email;
            user.language = this.language;
            user.login_type = 'GOOGLE';
            user.type = this.userType;
            user.gender = 'other';
            user.password = '';
            user.biz_type = 'B2C';
            user.app = 'lab4chemistry';
            user.isGuest = false;
            user.image = response.photoUrl;
            user.third_party_id = sha1(response.id + this.tokenSHA);
            this.subscription.add(
              this.userService.newUser(user).subscribe({
                next: (resp) => {
                  Swal.fire(resp.message, user.first_name, 'success');
                  this.subscription.add(
                    this.userService.login(user, false, false).subscribe({
                      next: (_resp) => {
                        localStorage.setItem('platform', 'GOOGLE');
                        this.router.navigate([PrivateRoutes.HOME]);
                      },
                    }),
                  );
                },
              }),
            );
          },
          (error) => {
            if (error) {
              Swal.close();
            }
          },
        )
        .catch((error) => {
          if (error) {
            Swal.close();
          }
        });
    } else {
      Swal.close();
      return;
    }
  }

  /** Function that verifies if the two passwords are equals
   * @param variable1 Variable that brings password
   * @param variable2 Variable that brings the confirmation of the password
   */
  areTheyEquals(variable1: string, variable2: string) {
    return (group: UntypedFormGroup) => {
      const pass1 = group.controls[variable1].value;
      const pass2 = group.controls[variable2].value;

      if (pass1 === pass2) {
        return null;
      }

      return {
        areTheyEquals: true,
      };
    };
  }

  /** Function that shows modal */
  showModal() {
    this.modalComingSoonService.showModal();
    return true;
  }

  /** Function to change language with Transloco
   * @param language Variable to save the actual language of languageService
   */
  selectLanguage(language: string = this.languageService.getLanguage()) {
    this.translocoService.setActiveLang(language);
  }

  /** Function to change language localStorage
   * @param language Variable to save the actual language
   */
  changeLanguage(language: string) {
    if (language === 'es' || language === 'en') {
      this.language = language;
      this.languageService.applyLanguage(this.language);
      window.location.reload();
    } else {
      return;
    }
  }
}
