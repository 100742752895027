import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Config
import { mixpanelEvent } from 'src/app/config/mixpanel-events';
import { API_V3_URL } from '../../config/config';

// Models
import { User } from '../../models/user.model';

// Alerts
import Swal from 'sweetalert2';

// RXJS
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// Get language
import { LanguageService } from '../language/language.service';
import { SidebarService } from '../shared/sidebar.service';
import { ModalAppService } from 'src/app/components/modal-app/modal-app.service';
import { MixpanelService } from '../analytics/mixpanel.service';
import { GlobalStateService } from 'src/app/global-state.service';
import { ApiCallBase } from '../api-lab4u/apiCallBase';

/**
 * Component to manage all the user characteristics
 */
@Injectable()
export class UserService extends ApiCallBase {
  /**
   * @param language Variable to save the actual language
   */
  language: string;
  /**
   * @param userId Variable to save user ID
   */
  userId: string;
  /**
   * @param user Variable to save the connected user data
   */
  user: User;
  /**
   * @param token Variable to save the token
   */
  token: string;
  /**
   * @param message Variable to save the message
   */
  message: string;
  /**
   * @param plan_id Variable to save the plan_id
   */
  plan_id: string;
  /**
   * @param plan_name Variable to save the plan_name
   */
  plan_name: string;

  /**
   * Following variables declared
   * @param languageService Service that works with the page language
   * @param http Service that allows http request
   * @param router Variable that manage the routes
   */
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    private sidebarService: SidebarService,
    private modalAppService: ModalAppService,
    public mixpanelService: MixpanelService,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
  }

  ngOnInit() {
    this.loadStorage();
  }

  /**
   * Function that returns only if the user has sign in or not
   */
  isLogin() {
    return this.token.trim().length > 40 ? true : false;
  }

  /**
   * Function that loads the information from the localStorage
   */
  loadStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userId = localStorage.getItem('userId');
      this.language = localStorage.getItem('language');
      setTimeout(() => {
        this.initializeMixpanelUserProfile(this.user, this.userId);
      }, 0);
    } else {
      this.token = '';
      this.user = null;
      this.userId = '';
    }
  }

  /**
   * Function that saves the information at the localStorage
   * @param userId Variable that brings the user ID
   * @param token Variable that brings the user token
   * @param user Variable that brings the user data
   */
  saveStorage(userId: string, token: string, user: User): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        this.token = token;
        this.userId = userId;
        localStorage.setItem('userId', userId);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('new_user_login', 'true');

        this.infoUser().subscribe({
          next: (resp: any) => {
            const user = resp.response.user;
            localStorage.setItem('user', JSON.stringify(user));
            this.initializeMixpanelUserProfile(user, userId);
            this.user = user;
            observer.next(true);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });
      } catch (error) {
        observer.error(error);
      }
    });
  }

  /**
   * Function that erases all the information (except the language) from the localStorage when signing out
   */
  logout() {
    this.user = null;
    this.token = '';
    this.userId = '';

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('App');
    localStorage.removeItem('settings');
    localStorage.removeItem('new_user_login');
    localStorage.removeItem('planningId'); // Important when logging out on planning views.
    sessionStorage.removeItem('mixpanelInitialized');
    sessionStorage.removeItem('verifiedToken');

    // TODO - Check why this reset fails after token verification to fix root problem
    try {
      this.mixpanelService.reset();
    } catch (_error) {}

    this.router.navigate(['/sign-in']);
  }

  /**
   * Function that through a request post sign/in the user and return their data
   * @param user Variable that brings all the user information
   * @param rememberUser Variable to remember the users e/mail at the localStorage
   */
  login(
    user: User,
    rememberUser: boolean = false,
    triggerSignInEvent: boolean = true,
  ) {
    if (rememberUser) localStorage.setItem('email', user.email);
    else localStorage.removeItem('email');

    const url = `${API_V3_URL}/auth/login`;

    return this.http.post(url, user, this.getHttpOptionsWithoutTokenV3()).pipe(
      switchMap((resp: any) => {
        user.password = '****';
        this.userId = resp.userId;
        return this.saveStorage(resp.userId, resp.token, user).pipe(
          map(() => {
            if (triggerSignInEvent) this.triggerIntroSignInSucceededEvent();
            else this.triggerIntroSignUpSucceededEvent();
            return true;
          }),
        );
      }),
      catchError((err) => {
        if (err.status === 401) {
          if (this.language === 'es') {
            Swal.fire('Error', 'Usuario o contraseña incorrecta', 'error');
          } else {
            Swal.fire('Error', 'User or password invalid', 'error');
          }
        } else if (err.status === 404) {
          if (this.language === 'es') {
            Swal.fire({
              title: 'Error',
              text: 'Correo no registrado. ¡Créate una cuenta!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate(['/sign-up']);
              }
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Unregistered mail. Create an account!',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'custom-swal-modal',
              },
            }).then((result) => {
              if (result.value) {
                this.router.navigate(['/sign-up']);
              }
            });
          }
        } else {
          if (this.language === 'es') {
            Swal.fire('Error', 'Error al iniciar sesión', 'error');
          } else {
            Swal.fire('Error', 'Sign in error', 'error');
          }
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }

  /**
   * Function to obtain the 100% of the data of the user
   */
  infoUser() {
    const url = `${API_V3_URL}/users/${this.userId}`;
    return this.http.get(url, this.getHttpOptionsV3());
  }

  user_role: string;
  //* gets the user's role to use in the directive
  getRole() {
    this.user_role = this.user.user_type;
    return this.user_role;
  }
  user_deal: string;
  getInstitution() {
    this.user_deal = this.user.deal;
    return this.user_deal;
  }

  user_id: string;
  getId() {
    this.user_id = this.user.userId;
    return this.user_id;
  }
  user_email: string;
  getUserEmail() {
    this.user_email = this.user.email;
    return this.user_email.toUpperCase();
  }

  /**
   * Shows modal to select App if there isn't one already selected
   */
  showAppModal() {
    if (
      this.sidebarService.getApp() === 'undefined' ||
      this.sidebarService.getApp() === null
    ) {
      this.sidebarService.applyApp('LAB4PHYSICS');
      localStorage.setItem('App', JSON.stringify('LAB4PHYSICS'));
    } else this.modalAppService.hideModal();
  }

  /**
   * Function to initialize the Mixpanel User Profile
   */
  initializeMixpanelUserProfile(user: User, userId: string) {
    this.mixpanelService.init(
      user.email,
      user.first_name,
      userId,
      user.last_name,
      user.usergroup,
      user.user_type,
    );
  }

  /**
   * Function to register a new user
   * @param user Variable that brings the data completed by the user
   */
  newUser(user: User) {
    const url = `${API_V3_URL}/auth/register`;

    return this.http.post(url, user, this.getHttpOptionsWithoutTokenV3()).pipe(
      map((resp: any) => {
        Swal.fire(
          this.language === 'es'
            ? 'Su cuenta se ha creado exitosamente'
            : 'Your account has been created successfully',
          user.first_name + ' ' + user.last_name,
          'success',
        );
        return resp.userId;
      }),
      catchError((err) => {
        if (err.status === 409) {
          if (this.language === 'es') {
            Swal.fire('Error', 'El usuario ya existe', 'error');
          } else {
            Swal.fire('Error', 'User already exists', 'error');
          }
        } else {
          if (this.language === 'es') {
            Swal.fire('Error', 'Error al crear la cuenta', 'error');
          } else {
            Swal.fire('Error', 'Error creating the account', 'error');
          }
        }
        return throwError(() => new Error(err.message));
      }),
    );
  }

  /**
   * Function that returns the experiments and tools
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   */
  loadExperimentsAndTools(language: string, application: string) {
    const url = `${API_V3_URL}/content/exp-categories-and-tools?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;

    return this.http.get(url, this.getHttpOptionsV3()).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        switch (error.status) {
          default:
            errorMessage = `Error ${error.status}, ${error.error.message}`;
        }
        return throwError(() => new Error(errorMessage));
      }),
    );
  }

  /**
   * Function that returns the Experiment instances of an Experiment Category
   * @param language Variable that brings the current language of the page
   * @param application Variable that brings the actual app
   * @param categoryId Variable that brings the category ID to filter
   */
  loadExperimentsByCategory(
    language: string,
    application: string,
    categoryId: string,
  ) {
    const url = `${API_V3_URL}/content/experiments?user_id=${this.userId}&language=${language}&device=WEB&app=${application}&category_id=${categoryId}`;
    return this.http.get(url, this.getHttpOptionsV3());
  }

  /**
   * Function that returns the information of an Experiment Category
   * @param language Variable that brings the current language of the page
   * @param application Variable that brings the actual app
   * @param categoryId Experiment Category ID to retrieve
   * @returns
   */
  loadExperimentCategoryProfile(
    language: string,
    application: string,
    experimentCategoryId: string,
  ) {
    const url = `${API_V3_URL}/content/categories/${experimentCategoryId}?user_id=${this.userId}&language=${language}&device=WEB&app=${application}`;
    return this.http.get(url, this.getHttpOptionsV3());
  }

  /**
   * Function to verify the current token of the user.
   * @param userId ID of the current User
   * @param token Current User token
   */
  verifyToken(userId: string, token: string): Observable<boolean> {
    const url = `${API_V3_URL}/auth/verify-token?user_id=${userId}&user_token=${token}`;
    return this.http.get(url, this.getHttpOptionsV3()).pipe(
      map((data: any) => data.response?.is_valid || false),
      catchError((error) => {
        console.error('Error verifying user token: ', error);
        throw error;
      }),
    );
  }

  /**
   * Function that returns the PDF or filtered videos by the entity and the entityId
   *
   * NOT ALL MATERIALS ARE AVAILABLE YET ON LAB4CHEMISTRY
   * @param language Variable that brings the current language actual of the page
   * @param application Variable that brings the actual app
   * @param entityId ID referred to the entity
   * @param entity Name of the entity from where you want to obtain the material
   */
  getEduMaterials(
    language: string,
    application: string,
    entityId: string,
    entity: string,
  ) {
    const url = `${API_V3_URL}/edu-materials/${entity}?user_id=${this.userId}&language=${language}&device=WEB&app=${application}&id=${entityId}`;

    const initialResponse = this.http.get(url, this.getHttpOptionsV3());

    const materialList = initialResponse.pipe(
      tap((res: any) => {
        const materials: any[] = res.response;
        materials.forEach((mat) => {
          mat.url_download = mat.url_download.replace('http://', 'https://');
        });
      }),
    );

    return materialList;
  }

  requestPasswordReset(user: User) {
    const url = `${API_V3_URL}'/auth/reset-password-request`;

    return this.http.post(url, user, this.getHttpOptionsWithoutTokenV3()).pipe(
      map((resp: any) => {
        user.password = '****';
        return resp;
      }),
      catchError((err) => {
        return throwError(() => new Error(err.message));
      }),
    );
  }

  triggerIntroSignInSucceededEvent() {
    this.mixpanelService.track(mixpanelEvent.INTRO_SIGN_IN_SUCCEEDED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
  }

  triggerIntroSignUpSucceededEvent() {
    this.mixpanelService.track(mixpanelEvent.INTRO_SIGN_UP_SUCCEEDED, {
      source_name: mixpanelEvent.SOURCE_NAME,
    });
  }
}
