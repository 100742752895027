import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../language/language.service';
import { ApiCallBase } from './apiCallBase';
import { API_V3_URL } from '../../config/config';
import { newsItem } from 'src/app/models/news.model';
import { Observable } from 'rxjs';
import { GlobalStateService } from 'src/app/global-state.service';

@Injectable({
  providedIn: 'root',
})
export class NewsSectionService extends ApiCallBase {
  constructor(
    public languageService: LanguageService,
    public http: HttpClient,
    public router: Router,
    public globalStateService: GlobalStateService,
  ) {
    super(languageService, http, router, globalStateService);
    this.language =
      this.language === 'es'
        ? 'ES-LA'
        : this.language === 'en'
        ? 'EN-US'
        : this.language;
  }

  getNews(): Observable<newsItem[]> {
    let url = `${API_V3_URL}/news?user_id=${this.userId}&language=${this.language}&device=WEB&platform=PORTAL`;
    return this.http.get<newsItem[]>(url, this.getHttpOptionsV3());
  }
}
