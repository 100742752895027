<div
  class="home-header"
  [ngClass]="{ 'home-header-toggle': !newsToggleStatus }"
>
  <app-header></app-header>
</div>

<div class="">
  <app-news (toggleNewsStatus)="receiveToggleNewsStatus($event)"></app-news>
</div>
<div class="d-none d-md-block">
  <app-sidebar [sidebarElements]="sidebarElements"></app-sidebar>
</div>

<main
  class="p-4 px-sm-5 ml-0 ml-md-5 home-background"
  *transloco="let text; read: 'HOME'"
>
  <div
    class="mt-1 home-header mx-auto mx-md-2"
    [ngClass]="{ 'home-header-toggle': !newsToggleStatus }"
  >
    <h2 class="home-title text-center text-md-left">
      {{ text('CLASSES-PLANNING') }}
    </h2>

    <div class="sections-grid mx-auto mx-md-0">
      <div class="classrooms-btn">
        <app-section-button
          [userType]="user.user_type"
          [showview]="'planningView'"
          [linkToSection]="planningSection"
          [titleSection]="text('PLANNING')"
          [subtitleSection]="text('PLANNING-SUBTITLE')"
          [imageSection]="'../../assets/images/icon/gen4u-logo.svg'"
          [isActive]="true"
          [backgroundClass]="'planning-background'"
          [clickEvent]="'planningSelected'"
          [dealTag]="
            user.deal !== null &&
            user.deal.toUpperCase().includes('INACAP') &&
            (user.user_type === 'TEACHER' || user.user_type === 'ADMIN')
              ? '../../assets/images/inacap-resources/logo.svg'
              : ''
          "
        ></app-section-button>
      </div>
      <div class="metrics-and-topics-section">
        <div class="classrooms-and-planning-section">
          <app-section-button
            [userType]="user.user_type"
            [showview]="'exercisingView'"
            [linkToSection]="trainingQuizzesSection"
            [titleSection]="text('QUIZZES')"
            [imageSection]="'../../assets/images/icon/quizzes.svg'"
            [isActive]="true"
            [backgroundClass]="'training-quizzes-background'"
            [inactiveText]=""
            [clickEvent]="'trainingQuizzesSelected'"
          ></app-section-button>
        </div>
        <div class="classrooms-and-planning-section">
          <app-section-button
            [showview]="'topicsView'"
            [userType]="user.user_type"
            [linkToSection]="topicsSection"
            [titleSection]="text('TOPICS')"
            [imageSection]="'../../assets/images/new-home/topics-ic.svg'"
            [isActive]="true"
            [backgroundClass]="'topics-background'"
            [inactiveText]=""
            [clickEvent]="'themesSelected'"
          ></app-section-button>
        </div>
        <div
          *ngIf="
            !(user.deal !== null && user.deal.toUpperCase().includes('INACAP'))
          "
          class="topics-section"
        >
          <app-section-button
            [showview]="'claasroomtopicsView'"
            [userType]="user.user_type"
            [linkToSection]="classroomSection"
            [titleSection]="
              isTeacher ? text(teacherSectionTitle) : text(studentSectionTitle)
            "
            [imageSection]="
              isTeacher
                ? '../../assets/images/icon/myclass-ic.svg'
                : '../../assets/images/icon/myclass-ic-student.svg'
            "
            [isActive]="true"
            [clickEvent]="'classroomsSelected'"
          ></app-section-button>
        </div>
      </div>
    </div>
    <div class="apps-list-section mx-auto mx-md-0">
      <h2 class="text-center text-md-left home-title">
        {{ text('CONTENT-EXPERIMENTS') }}
      </h2>
      <app-apps-cards-list></app-apps-cards-list>
    </div>
  </div>
</main>
