<a class="pointer-on-hover" (click)="clickOnComponentEvent()">
  <div
    class="section-background d-flex w-100 h-100"
    [ngClass]="{
      'coming-soon': !isActive,
      'topics-background': backgroundClass === 'topics-background',
      'training-quizzes-background':
        backgroundClass === 'training-quizzes-background',
      'planning-background': backgroundClass === 'planning-background'
    }"
  >
    <div *ngIf="dealTag" class="deal-tag">
      <img [src]="dealTag" class="deal-tag-image" />
    </div>
    <div *ngIf="isActive" class="section-button-content">
      <img class="active-image" [src]="imageSection" />
      <h3 class="section-title">
        {{ titleSection }}
      </h3>
      <h4 *ngIf="subtitleSection" class="section-subtitle">
        {{ subtitleSection }}
      </h4>
    </div>

    <div *ngIf="!isActive" class="m-auto text-center d-block">
      <img [src]="imageSection" alt="" class="inactive-image mb-3" />
      <div class="d-flex m-auto text-center coming-soon-opacity">
        <h3 class="m-auto section-title">{{ titleSection }}</h3>
      </div>
    </div>
  </div>
</a>
